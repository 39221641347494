import React from "react";
import { Link } from "gatsby";
// import Slider from "../components/slider";

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            isPopupActive: false,
            isBannerActive: true,
            aboutIsActive: false,
            serviceIsActive: false,
            newsIsActive: false,
            menu: false
        };


        this.toggleAbout = this.toggleAbout.bind(this);
        this.toggleService = this.toggleService.bind(this);
        this.toggleNews = this.toggleNews.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
    }
    // componentDidUpdate(prevProps, prevState) {
    //     console.log(prevProps)
    // }
    toggleMenu() {
        this.setState({
            menu: !this.state.menu,
        });
    }
    toggleAbout(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.setState({
            aboutIsActive: !this.state.aboutIsActive,
            serviceIsActive: false,
            newsIsActive: false,
        });
    }
    toggleService(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.setState({
            serviceIsActive: !this.state.serviceIsActive,
            aboutIsActive: false,
            newsIsActive: false,
        });
    }
    toggleAll() {
        this.setState({
            serviceIsActive: false,
            aboutIsActive: false,
            newsIsActive: false,
        });
    }
    toggleNews(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.setState({
            newsIsActive: !this.state.newsIsActive,
            serviceIsActive: false,
            aboutIsActive: false,
        });
    }
    render() {
        return (
            <div className="relative bg-white overflow-visible" onClick={this.toggleAll}>
                <div className="w-full bg-blue fixed z-50">
                    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="pr-0 sm:pr-16 sm:text-center sm:px-16">
                            <p className="font-medium text-white">
                                <span className="text-xs sm:text-base md:hidden">
                                    We have a brand new book out!
                                </span>
                                <span className="text-sm sm:text-base hidden md:inline">
                                    We have a brand new book out!
                                </span>
                                <span className="text-xs sm:text-base sm:ml-2 inline-block">
                                    <a target="_blank" href="https://www.docs-store.co.uk/product-page/the-practical-guide-to-grade-d-fire-alarm-systems" className="text-white font-bold underline"> Learn more <span aria-hidden="true">&rarr;</span></a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full sticky top-24 pt-10 bg-white z-40 mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center pt-6 pb-4 lg:justify-start lg:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a className="text-black text-lg font-bold" href="/">
                                <span className="sr-only">Tim Benstead Associates</span>
                                <img className="hidden xl:block mx-auto" src="../assets/banner.png" />
                                <img className="xl:hidden h-12" src="../assets/logo.png" alt="Tim Benstead" />
                            </a>

                        </div>
                        <div className="-mr-2 -my-2 lg:hidden">
                            <button
                                onClick={this.toggleMenu}
                                type="button"
                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open menu</span>

                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            </button>
                        </div>
                        <nav className="hidden lg:flex space-x-10">
                            <div className="relative">
                                <button
                                    onClick={e => this.toggleAbout(e)}
                                    type="button"
                                    className="text-blue group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-black"
                                    aria-expanded="false"
                                >
                                    <span>About Us</span>

                                    <svg
                                        className="text-blue ml-2 h-5 w-5 group-hover:text-black"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                <div
                                    className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                    style={{
                                        display: `${this.state.aboutIsActive ? "block" : "none"}`,
                                    }}
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            <Link
                                                to="/about"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        About us
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        What do we do?
                                                    </p>
                                                </div>
                                            </Link>

                                            <Link
                                                to="/history"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Our history
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Get a better understanding of our journey.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative">
                                <button
                                    onClick={e => this.toggleService(e)}
                                    type="button"
                                    className="text-blue group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-black"
                                    aria-expanded="false"
                                >
                                    <span>Our Services</span>

                                    <svg
                                        className="text-blue ml-2 h-5 w-5 group-hover:text-black"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                <div
                                    className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                    style={{
                                        display: `${this.state.serviceIsActive ? "block" : "none"}`,
                                    }}
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            <Link
                                                to="/services"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Services
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        What do we do?
                                                    </p>
                                                </div>
                                            </Link>

                                            <Link
                                                to="/policy"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Policy work
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Get a better understanding of our journey.
                                                    </p>
                                                </div>
                                            </Link>

                                            <Link
                                                to="/publishing"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Publishing
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Get a better understanding of our journey.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a
                                href="/training"
                                className="text-base font-medium text-blue hover:text-black"
                            >
                                Training
                            </a>

                            <div className="relative">
                                <button
                                    onClick={e => this.toggleNews(e)}
                                    type="button"
                                    className="text-blue group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-black"
                                    aria-expanded="false"
                                >
                                    <span>Latest News</span>

                                    <svg
                                        className="text-blue ml-2 h-5 w-5 group-hover:text-black"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                <div
                                    className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                                    style={{
                                        display: `${this.state.newsIsActive ? "block" : "none"}`,
                                    }}
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            <Link
                                                to="/latest-news"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Latest news
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        This is where our articles will appear plus details of our new work.
                                                    </p>
                                                </div>
                                            </Link>

                                            <Link
                                                to="/calendar"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Calendar
                                                    </p>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        See what meet-ups and other events we might be
                                                        planning near you.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Link
                                to="/contact"
                                className="text-base font-medium text-blue hover:text-black"
                            >
                                Contact Us
                            </Link>
                        </nav>
                    </div>
                </div>

                {this.state.menu ? (
                    <div className="absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right lg:hidden">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div className="-mr-2">
                                        <button
                                            onClick={this.toggleMenu}
                                            type="button"
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        >
                                            <span className="sr-only">Close menu</span>

                                            <svg
                                                className="h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid gap-y-8">
                                        <Link
                                            to="/about"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >

                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                About Us
                                            </span>
                                        </Link>

                                        <Link
                                            to="/services"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >

                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Our Services
                                            </span>
                                        </Link>

                                        <Link
                                            to="/training"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >
                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Training
                                            </span>
                                        </Link>

                                        <Link
                                            to="/latest-news"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >

                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Latest News
                                            </span>
                                        </Link>

                                        <Link
                                            to="/contact"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >

                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Contact Us
                                            </span>
                                        </Link>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-6 px-5 space-y-6">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (null)}
            </div>
        );
    }
}

export default Navigation;
