import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

export default () => (
    <StaticQuery
        query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
        render={(data) => (
            <footer className="bg-white" aria-labelledby="footerHeading">



                <h2 id="footerHeading" className="sr-only">Footer</h2>
                <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="space-y-8 xl:col-span-1">
                            <img className="h-12" src="../assets/logo.png" alt="Tim Benstead" />
                            {/* <p className="text-gray-500 text-base">
                                We are an Electrical Engineering Consultancy providing a range of services covering the electrical installation sector.
                            </p> */}
                            <div className="mt-9">
                                <div className="flex">
                                    <div className="flex-shrink-0">

                                        <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                        </svg>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <p>
                                            +44 (01482) 572698
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-2 flex">
                                    <div className="flex-shrink-0">

                                        <svg className="h-6 w-6 text-gray-400" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="#a1a1a9">
                                                <path d="m946.88 138.28c0-73.828-59.766-133.59-133.59-133.59h-426.56c-73.828 0-133.59 59.766-133.59 133.59v923.44c0 73.828 59.766 133.59 133.59 133.59h426.56c73.828 0 133.59-59.766 133.59-133.59zm-614.06 100.78h534.38v658.59h-534.38zm480.7 876.56h-427.03c-29.766 0-53.672-24.844-53.672-54.375v-83.906h534.38v83.906c0 29.531-23.906 54.375-53.672 54.375zm53.672-976.88v20.625h-534.38v-20.625c0-29.766 23.906-54.375 53.672-54.375h427.03c29.766 0 53.672 24.844 53.672 54.375z" />
                                                <path d="m657.19 1007.8h-114.38c-22.031 0-39.844 17.812-39.844 39.844s17.812 39.844 39.844 39.844h114.38c22.031 0 39.844-17.812 39.844-39.844s-17.812-39.844-39.844-39.844z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <p>
                                            07375 666381
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-2 flex">
                                    <div className="flex-shrink-0">

                                        <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <div className="ml-3 text-base text-gray-500">
                                        <p>
                                            tb@timbensteadassociates.org
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex space-x-6 items-center">


                                <Link to="/contact" className="bg-blue border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-white font-medium text-indigo-600 hover:bg-indigo-50">Contact Us</Link>

                                <a href="https://linkedin.com" className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">LinkedIn</span>
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z" fill="#A1A1A9" />
                                    </svg>

                                </a>

                                {/* <a href="https://facebook.com" className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Facebook</span>
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                                    </svg>
                                </a>

                                <a href="http://twitter.com" className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Twitter</span>
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                        About us
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link to="/about" className="text-base text-gray-500 hover:text-gray-900">
                                                About Us
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/history" className="text-base text-gray-500 hover:text-gray-900">
                                                Our History
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/privacy" className="text-base text-gray-500 hover:text-gray-900">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                        Our Services
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link to="/services" className="text-base text-gray-500 hover:text-gray-900">
                                                Services
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/policy" className="text-base text-gray-500 hover:text-gray-900">
                                                Policy Work
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/publishing" className="text-base text-gray-500 hover:text-gray-900">
                                                Publishing
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                        Training
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link to="/training" className="text-base text-gray-500 hover:text-gray-900">
                                                Training
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                                <div className="mt-12 md:mt-0">
                                    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                        Latest News
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                        <li>
                                            <Link to="latest-news" className="text-base text-gray-500 hover:text-gray-900">
                                                Latest News
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/calendar" className="text-base text-gray-500 hover:text-gray-900">
                                                Calendar
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 border-t border-gray-200 py-8">
                        <p className="text-base text-gray-400 xl:text-center">
                            &copy; 2022 Tim Benstead, Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        )}
    />
);
