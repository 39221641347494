import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation";
import Footer from "../components/footer";

export default ({ children }) => {
    const data = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
    );

    // const [close, setCloseMenu] = useState(false)

    // const closeMenu = () => {
    //     console.log('closing menu')
    //     setCloseMenu(true)
    //     setCloseMenu(false)
    // }

    return (
        <div className="bg-white overflow-visible">
            <header className="overflow-visible">
                <Navigation title={data.site.siteMetadata.title} />
            </header>
            {/* <div onClick={() => closeMenu()}> */}
            {children}
            {/* </div> */}
            <Footer />
        </div>
    );
};
